import logo from './logo.svg';
import './App.css';
import Product from './Pages/Product';
import {BrowserRouter as Router ,Route,Routes } from "react-router-dom";
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/:name" exact element={ <Product 
      />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
