import React,{useState,useEffect} from 'react'
import StlViewer from './STL'
import axios from "axios";
import {useParams} from "react-router-dom";

function Product() {
  const {name} = useParams();
  console.log(name);
  const [productOutline,setProductOutline]= useState(null);
  const [spec,setSpec]= useState(null);
  const [details,setDetails] = useState(null);

  const getOutLine=async()=>{
   try{
    const { data } = await axios.get(`https://axgura-homepage.herokuapp.com/v1/product/content/${name}`);
    if(data){
      console.log(data);
      setProductOutline(data.data);
    }else{
      console.log("Product not found.");
    }
   }catch(error){
     console.log(error);
   }
  }

  const getSpecs=async()=>{
    try{
      const { data } = await axios.get(`https://axgura-homepage.herokuapp.com/v1/product/speculation/${name}`);
    if(data){
      console.log(data);
      setSpec(data.data);
    }else{
      console.log("Product not found.");
    }
    }catch(error){
      console.log(error);
    }
  }

  const getDetails=async()=>{
   try{
    const {data} = await axios.get(`https://axgura-homepage.herokuapp.com/v1/product/product-details/${name}`);
    if(data){
      console.log(data);
      setDetails(data.data);
    }else{
      console.log("No details fethced");
    }
   }catch(error){
    console.log(error);
   }
  }

  useEffect(()=>{
    if(name){
      getOutLine();
    getSpecs();
    getDetails();
    }

  },[]);

  const [ displayImage,setDisplayImage ] = useState(null);

  const changeDisplayImage=(image)=>{
    console.log({image});
    setDisplayImage(image);
  }
  

  return (
    <div>
        <body>
<header>
<div class="page__title-area">
      <div class="container">
        <div class="page__title-container">
          <ul class="page__titles">
            <li>
              <a href="/">
                <svg>
                  {/* <use xlink:href="./images/sprite.svg#icon-home"></use> */}
                </svg>
              </a>
            </li>
    {details &&  <li class="page__title">{details.name}</li>}
          </ul>
        </div>
      </div>
    </div>
</header>

  <main id="main">
    <div class="container">
      <section class="section product-details__section">
        <div class="product-detail__container">
          <div class="product-detail__left">
            <div class="details__container--left">
              <div class="product__pictures">
                {productOutline && productOutline.map(x=>
                   <div class="pictures__container">
                    <a onClick={()=>changeDisplayImage(x.image)}>
                   <img class="picture" src={x.image} id={x.name} />
                   </a>
                 </div>
                 )}
              </div>
              <div class="product__picture" id="product__picture">
                 <div class="rect" id="rect"></div> 
                <div class="picture__container">
                  {details && <img src={displayImage?displayImage:details.images[0]} id={details.name} />}
                {/* {details && details.stlFile ?<div>{/**<StlViewer stl={stlFile}/> </div>:<div><img src={details.images[0]} id={details.name} /></div>*/}
                  
</div>
</div>
              <div class="zoom" id="zoom"></div>
            </div>

            {details && details.name !== "axgura" && <div class="product-details__btn">
              <a class="add" href={`https://shop.axgura.com/${details.product_id}`}>
                <span>
                  
                </span>
                SHOP NOW</a>
              
              <a class="buy" href={`${details.blog_id}`}>
                <span>
                  
                </span>
                READ BLOG
              </a>
            </div> }
            {details && details.name !== "axgura" &&  <div class="product-details__btn">
              <a class="add" href={details.survey_id}>
                <span>
                  
                </span>
                TAKE SURVEY</a>
              
              <a class="buy" href="#">
                <span>
                  
                </span>
                LIKES ({details.likes})
              </a>
            </div> }
          </div>

          <div class="product-detail__right">
            <div class="product-detail__content">
              {details && <h3>{details.name}</h3>}
              <div class="price">
              {details && details.name !== "axgura" && details.price?<span class="new__price">Proce: $250.99</span>:<span>Price: Not available</span>}
              </div>
               <div class="product__review">
              
                <a href="#" class="rating__quatity">Description</a>
              </div>
              {details && <p>
               {details.description}
              </p>}
              <div class="product__info-container">
                <ul class="product__info">
                
                  <li>
                    <span>Launch Date:</span>
                    {details && details.ready == true? <a href="#" class="new__price">{details.launch}</a>:<a>Not released</a>}
                  </li>
                  <li>
                    <span>Brand:</span>
                    {details && details.name !== "axgura" &&  <a href="#">{details.brand}</a>}
                    </li>
                  <li>
                    <span>Product Type:</span>
                    {details && details.name !== "axgura" && <a href="#">Hardware</a>}
                  </li>
                  <li>
                    <span>Availability:</span>
                    {details && details.name !== "axgura" && details.totalProduct ?<a href="#" class="in-stock">In Stock ({details.totalProduct} Items)</a>:<a href="#" class="in-stock">No Stocks</a>}
                  </li>
                </ul>
              
              </div>
            </div>
          </div>
        </div>

        <div class="product-detail__bottom">
          {/* <div class="title__container tabs">

            <div class="section__titles category__titles ">
              <div class="section__title detail-btn active" data-id="description">
                <span class="dot"></span>
                <h1 class="primary__title">Description</h1>
              </div>
            </div>

            <div class="section__titles">
              <div class="section__title detail-btn" data-id="reviews">
                <span class="dot"></span>
                <h1 class="primary__title">Reviews</h1>
              </div>
            </div>

            <div class="section__titles">
              <div class="section__title detail-btn" data-id="shipping">
                <span class="dot"></span>
                <h1 class="primary__title">Shipping Details</h1>
              </div>
            </div>
          </div> */}

          {/* <div class="detail__content">
            <div class="content active" id="description">
              <p>Nam tempus turpis at metus scelerisque placerat nulla deumantos solicitud felis. Pellentesque diam
                dolor, elementum etos lobortis des mollis ut risus. Sedcus faucibus an sullamcorper mattis drostique des
                commodo pharetras loremos.Donec pretium egestas sapien et mollis.
              </p>
              <h2>Sample Unordered List</h2>
              <ul>
                <li>Comodous in tempor ullamcorper miaculis</li>
                <li>Pellentesque vitae neque mollis urna mattis laoreet.</li>
                <li>Divamus sit amet purus justo.</li>
                <li>Proin molestie egestas orci ac suscipit risus posuere loremous</li>
              </ul>
              <h2>Sample Ordered Lista</h2>
              <ol>
                <li>Comodous in tempor ullamcorper miaculis</li>
                <li>Pellentesque vitae neque mollis urna mattis laoreet.</li>
                <li>Divamus sit amet purus justo.</li>
                <li>Proin molestie egestas orci ac suscipit risus posuere loremous</li>
              </ol>
              <h2>Sample Paragraph Text</h2>
              <p>Praesent vestibulum congue tellus at fringilla. Curabitur vitae semper sem, eu convallis est. Cras
                felis
                nunc commodo eu convallis vitae interdum non nisl. Maecenas ac est sit amet augue pharetra convallis nec
                danos dui. Cras suscipit quam et turpis eleifend vitae malesuada magna congue. Damus id ullamcorper
                neque. Sed vitae mi a mi pretium aliquet ac sed elit. Pellentesque nulla eros accumsan quis justo at
                tincidunt lobortis denimes loremous. Suspendisse vestibulum lectus in lectus volutpat, ut dapibus purus
                pulvinar. Vestibulum sit amet auctor ipsum.</p>
            </div>
           
            <div class="content" id="shipping">
              <h3>Returns Policy</h3>
              <p>You may return most new, unopened items within 30 days of delivery for a full refund. We'll also pay
                the return shipping costs if the return is a result of our error (you received an incorrect or defective
                item, etc.).</p>
              <p>You should expect to receive your refund within four weeks of giving your package to the return
                shipper, however, in many cases you will receive a refund more quickly. This time period includes the
                transit time for us to receive your return from the shipper (5 to 10 business days), the time it takes
                us to process your return once we receive it (3 to 5 business days), and the time it takes your bank to
                process our refund request (5 to 10 business days).
              </p>
              <p>If you need to return an item, simply login to your account, view the order using the 'Complete
                Orders' link under the My Account menu and click the Return Item(s) button. We'll notify you via
                e-mail of your refund once we've received and processed the returned item.
              </p>
              <h3>Shipping</h3>
              <p>We can ship to virtually any address in the world. Note that there are restrictions on some products,
                and some products cannot be shipped to international destinations.</p>
              <p>When you place an order, we will estimate shipping and delivery dates for you based on the
                availability of your items and the shipping options you choose. Depending on the shipping provider you
                choose, shipping date estimates may appear on the shipping quotes page.
              </p>
              <p>Please also note that the shipping rates for many items we sell are weight-based. The weight of any
                such item can be found on its detail page. To reflect the policies of the shipping companies we use, all
                weights will be rounded up to the next full pound.
              </p>
            </div>
          </div> */}
        </div>
      </section>

     
    </div>
    
  </main>

 
</body>
    </div>
  )
}

export default Product;